export const BugHuntingCoursesData = [
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Bug hunting Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/Rp69edBmFFo/maxresdefault.jpg",
        tag: "Bug Hunting",
        videoHeading: "Beginner to Advanced Bug Bounty Hunting Course",
        videoLink: "https://youtu.be/hDYqWZ11njU?list=PLbyncTkpno5FAC0DJYuJrEqHSMdudEffw",
        channelName: "PhD Security",
        channelLogo:
            "https://yt3.ggpht.com/1TEM6wyKF82rwwcPYTQIHD_OeVxH02kYWBpzqc7J3OerSrQZmgMTrtYRVi35arnfh9a3GYDv=s68-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Bug hunting Courses",
        channelLink: "https://www.youtube.com/channel/UCAndnmvdiphDqLLDrGnBuhA",
    },
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Bug hunting Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/hDYqWZ11njU/maxresdefault.jpg",
        tag: "Bug Hunting",
        videoHeading: "How to Get Started with Bug Bounty - Resource Lists & Advice",
        videoLink: "https://youtu.be/hDYqWZ11njU?list=PLbyncTkpno5FAC0DJYuJrEqHSMdudEffw",
        channelName: "InsiderPhD",
        channelLogo: "https://yt3.ggpht.com/ytc/AMLnZu9FToR3EOEIhCjUcq70BroVP_aoBYtlnC-Ncst7=s48-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Bug hunting Courses",
        channelLink: "https://www.youtube.com/c/InsiderPhD",
    },
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Bug hunting Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/7SfXpXAMiHw/maxresdefault.jpg",
        tag: "Bug Hunting",
        videoHeading: "Guide to Failing at Bug Bounties - Naive Recon",
        videoLink: "https://www.youtube.com/watch?v=7SfXpXAMiHw&list=PLhfP6zOcRP1f_FyWc_gk1fRz1mxst0QE5",
        channelName: "RogueSMG",
        channelLogo: "https://yt3.ggpht.com/ytc/AMLnZu-TT8JmxUj6Ojd1HiblwtJlCpt2zuDRziY4OA0L=s88-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Bug hunting Courses",
        channelLink: "https://www.youtube.com/c/RogueSMG",
    },
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Bug hunting Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/VC_JYH45s74/maxresdefault.jpg",
        tag: "Bug Hunting",
        videoHeading: "Guide to Failing at Bug Bounties - Naive Recon",
        videoLink: "https://www.youtube.com/watch?v=VC_JYH45s74&list=PLhfP6zOcRP1fXtBtxhTlu9KR-PKDA6biX",
        channelName: "RogueSMG",
        channelLogo: "https://yt3.ggpht.com/ytc/AMLnZu-TT8JmxUj6Ojd1HiblwtJlCpt2zuDRziY4OA0L=s88-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Bug hunting Courses",
        channelLink: "https://www.youtube.com/c/RogueSMG",
    },
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Bug hunting Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/CAGMC-AfR1o/maxresdefault.jpg",
        tag: "Bug Hunting",
        videoHeading: "No BS Guides",
        videoDescription:
            "This is my gift for you the ultimate getting started guide for bug bounties / ethical hacking / cybersecurity. In this video, I go through a ton of resources including books, courses, videos, podcasts, conferences, and give out some study guides for people who wanna get into bug bounties but they have no hacking experience, no experience in tech, some security experience but not in bug bounties. I hope you find this super useful, it's PACKED full of information, almost an hour of stuff to check out!.",
        videoLink: "https://www.youtube.com/watch?v=CAGMC-AfR1o&list=PLhfP6zOcRP1dCTjbENt4ilFKFwFpspOoS",
        channelName: "RogueSMG",
        channelLogo: "https://yt3.ggpht.com/ytc/AMLnZu-TT8JmxUj6Ojd1HiblwtJlCpt2zuDRziY4OA0L=s88-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Bug hunting Courses",
        channelLink: "https://www.youtube.com/c/RogueSMG",
    },
];
