import styled from "styled-components";

export const ExploitTagsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 10px;
    max-width: 800px;
`;

export const ExploitTag = styled.button`
    padding: 10px;
    border: solid 1px #212121;
    border-radius: 5px;
    background-color: ${(props) => (props.activeFunction ? "#ff6b08" : "black")};
    color: ${(props) => (props.activeFunction ? "black" : "white")};
`;
