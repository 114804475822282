export const BlueTeamCoursesData = [
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Blue Team Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/Bt5fh3wQUAQ/maxresdefault.jpg",
        tag: "Blue Team",
        videoHeading: "Blue Team Tutorials",
        videoLink: "https://www.youtube.com/watch?v=Bt5fh3wQUAQ&list=PLBf0hzazHTGNcIS_dHjM2NgNUFMW1EZFx",
        channelName: "HackerSploit",
        channelLogo:
            "https://yt3.ggpht.com/ytc/AMLnZu9nSdhYbPUgxIavX6vicAOFioLHhkuCNeHcp-Icpw=s48-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Blue Team Courses",
        channelLink: "https://www.youtube.com/c/HackerSploit",
    },
];
