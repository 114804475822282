export const LinuxCoursesData = [
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Linux Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/U1w4T03B30I/maxresdefault.jpg",
        tag: "Linux",
        videoHeading: "Linux for Ethical Hackers",
        videoLink: "https://youtu.be/U1w4T03B30I",
        channelName: "The Cyber Mentor",
        channelLogo: "https://yt3.ggpht.com/ytc/AKedOLRGnc0be-YXi3zenYi6s8uwgPxi_IrLBZ7Rigv7=s48-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Linux Courses",
        channelLink: "https://www.youtube.com/c/TheCyberMentor",
    },
    {
        id: "freeYoutubeCourses",
        sectionsHeading: "Linux Courses",
        topLine: "Free Courses",
        YTChannelImageLink: "https://i.ytimg.com/vi/T0Db6dVYyoA/maxresdefault.jpg",
        tag: "Linux",
        videoHeading: "Linux Essentials For Hackers",
        videoLink: "https://www.youtube.com/watch?v=T0Db6dVYyoA&list=PLBf0hzazHTGMh2fe2MFf3lCgk0rKmS2by",
        channelName: "HackerSploit",
        channelLogo:
            "https://yt3.ggpht.com/ytc/AMLnZu9nSdhYbPUgxIavX6vicAOFioLHhkuCNeHcp-Icpw=s48-c-k-c0x00ffffff-no-rj",
        timeStamp: "Oct 7 2022",
        alt: "Linux Courses",
        channelLink: "https://www.youtube.com/c/HackerSploit",
    },
];
