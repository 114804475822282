import React, { useState } from "react";
import { Wrapper } from "src/components/Dashboard/Profile/ProfileElements";
import { Data } from "./Data";
import { FunctionData } from "./BinaryExploitsData";
import { RouterLink } from "src/components/Tools/ToolsElements";
import { ExploitTag, ExploitTagsContainer } from "./BinaryExploitsElements";

export default function GtfoBin() {
    const [activeFunction, setActiveFunction] = useState("All");

    function handleActiveFunction(data) {
        setActiveFunction(data);
    }

    return (
        <Wrapper style={{ width: "60%" }}>
            <label style={{ fontSize: "48px", fontWeight: "bold", letterSpacing: "5px" }}>BinExploit</label>

            <ExploitTagsContainer>
                <ExploitTag
                    activeFunction={activeFunction === "All"}
                    style={{ backgroundColor: activeFunction === "All" ? "#ff6b08" : "black" }}
                    onClick={() => {
                        handleActiveFunction("All");
                    }}
                >
                    All
                </ExploitTag>

                {Object.keys(FunctionData).map((data) => (
                    <ExploitTag
                        activeFunction={activeFunction === data}
                        key={data}
                        onClick={() => {
                            handleActiveFunction(data);
                        }}
                    >
                        {data}
                    </ExploitTag>
                ))}
            </ExploitTagsContainer>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    padding: "30px",
                }}
            >
                {Object.keys(Data).map(
                    (key) =>
                        (activeFunction === "All" || Object.keys(Data[key].functions).includes(activeFunction)) && (
                            <div key={key} style={{ display: "flex", margin: "10px" }}>
                                <RouterLink
                                    style={{
                                        width: "180px",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        marginTop: "15px",
                                        textDecorationLine: "underline",
                                    }}
                                    to={"/tools/binexploits/" + key}
                                >
                                    {key}
                                </RouterLink>
                                <ExploitTagsContainer
                                    style={{
                                        justifyContent: "flex-start",
                                        maxWidth: "600px",
                                    }}
                                >
                                    {Object.keys(Data[key].functions).map((binaryFunction) => (
                                        <ExploitTag
                                            activeFunction={activeFunction === binaryFunction}
                                            key={binaryFunction}
                                            onClick={() => {
                                                handleActiveFunction(binaryFunction);
                                            }}
                                        >
                                            {binaryFunction}
                                        </ExploitTag>
                                    ))}
                                </ExploitTagsContainer>
                            </div>
                        ),
                )}
            </div>
        </Wrapper>
    );
}
