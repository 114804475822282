import styled from "styled-components";
// import { CgSoftwareDownload } from "react-icons/cg";
// import { MdContentCopy } from "react-icons/md";

export const ReconageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    color: #d0d7de;
`;
