import styled from "styled-components";

export const OSINTGameContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 150px 60px;

    /* background: #0e0e0e; */
    color: #b0b0b0;

    @media screen and (width <= 760px) {
        text-align: center;
    }
`;

export const GameContainer = styled.div`
    padding: 50px;
    text-align: start;
    width: 100%;
    border: #b25900 1px dashed;
    border-radius: 5px;

    /* @media screen and (width <= 760px) {
    } */
`;
export const Question = styled.h4`
    margin: 10px 0 5px;
`;
export const InputBox = styled.input`
    color: #545454;
    background: #e9ecef;
    border: 1px solid #545454;
    border-radius: 5px;
    width: 25%;
    height: 25px;
    padding: 0 10px;
`;

export const SubmitButton = styled.button`
    color: #2e8de1;
    background: transparent;
    border: 1px solid #2e8de1;
    border-radius: 5px;
    width: auto;
    margin: 0 5px;
    padding: 0 10px;
    height: 25px;

    &:hover {
        background: #2e8de1;
        color: #e9ecef;
    }
`;

export const HintButton = styled.button`
    color: #ff8100;
    background: transparent;
    border: 1px solid #ff8100;
    border-radius: 5px;
    width: auto;
    margin: 0 5px;
    padding: 0 10px;
    height: 25px;

    &:hover {
        background: #ff8100;
        color: #e9ecef;
    }
`;
